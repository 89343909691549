import { render, staticRenderFns } from "./DocsH4.vue?vue&type=template&id=08825204&scoped=true&"
import script from "./DocsH4.vue?vue&type=script&lang=js&"
export * from "./DocsH4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08825204",
  null
  
)

export default component.exports