<template>
    <div id="docs">
        <Header />
        <main>
            <div class="content">
                <div class="stackedit__left">
                    <div class="stackedit__toc" id="sidebar">
                        <ul>
                            <li>
                                <a class="active" href="#documentation">Documentation</a>
                                <ul>
                                    <li>
                                        <a href="#intro">Introduction</a>
                                    </li>
                                    <li>
                                        <a href="#login">Login</a>
                                    </li>
                                    <li>
                                        <a href="#subscription">Subscription</a>
                                    </li>
                                    <li>
                                        <a href="#new-address">Creating an address</a>
                                    </li>
                                    <li>
                                        <a href="#deactivating-address">Deactivating an address</a>
                                    </li>
                                    <li>
                                        <a href="#categories">Using categories</a>
                                    </li>
                                     <li>
                                        <a href="#api">API</a>
                                    </li>
                                    <li>
                                        <a href="#frequent-questions">Frequent Questions</a>
                                    </li>
                                    <li>
                                        <a href="#contact">Contact</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="stackedit__right">
                    <div class="stackedit__html">
                        <h1 id="documentation">MailPal Documentation</h1>
                        <docs-item id="intro" title="Introduction">
                            <p>Will the real Slim Shady please stand up?. I repeat, will the real Slim Shady please stand up?. We're gonna have a problem here. Y'all act like you never seen a white person before. Jaws all on the floor like Pam like Tommy just burst in the door. And started whoopin' her ass worse than before.</p>
                        </docs-item>
                         <docs-item id="login" title="Login">
                            <p>If you could count the skeletons in my closet. Under my bed and up under my faucet. Then you would know I've completely lost it. Is he nuts? No, he's insane. If you could count the skeletons in my closet. Under my bed and up under my faucet. Then you would know I've completely lost it. Is he nuts? No, he's insane.</p>
                        </docs-item>
                        <docs-item id="subscription" title="Subscription">
                            <docs-h3 id="choosing-subscription">Choosing a Subscription</docs-h3>
                            <p>Feminist women love Eminem. "Chicka, chicka, chicka, Slim Shady, I'm sick of him. Look at him, walkin' around, grabbin' his you-know-what. Flippin' the you-know-who," "Yeah, but he's so cute though.". Yeah, I probably got a couple of screws up in my head loose. But no worse than what's goin' on in your parents' bedrooms.</p>
                            <docs-h3 id="change-subscription">Changing current Subscription</docs-h3>
                            <p>I'm beginning to feel like a Rap God, Rap God. All my people from the front to the back nod, back nod. The way I'm racing around the track, call me NASCAR, NASCAR. Dale Earnhardt of the trailer park, the White Trash God. Kneel before General Zod. This planet's Krypton – no, Asgard, Asgard.</p>
                            <docs-h3 id="cancel-subscription">Cancel Subscription</docs-h3>
                            <p>Feminist women love Eminem. "Chicka, chicka, chicka, Slim Shady, I'm sick of him. Look at him, walkin' around, grabbin' his you-know-what. Flippin' the you-know-who," "Yeah, but he's so cute though.". Yeah, I probably got a couple of screws up in my head loose. But no worse than what's goin' on in your parents' bedrooms.</p>
                        </docs-item>
                        <docs-item id="api" title="API">
                            <p>Will the real Slim Shady please stand up?. I repeat, will the real Slim Shady please stand up?. We're gonna have a problem here. Y'all act like you never seen a white person before. Jaws all on the floor like Pam like Tommy just burst in the door. And started whoopin' her ass worse than before.</p>
                            <docs-h3 id="api-auth">Authentication</docs-h3>
                            <p>I'm Slim Shady, yes, I'm the real Shady. All you other Slim Shadys are just imitating. So won't the real Slim Shady please stand up. Please stand up, please stand up?. 'Cause I'm Slim Shady, yes, I'm the real Shady. All you other Slim Shadys are just imitating. So won't the real Slim Shady please stand up. Please stand up, please stand up?</p>
                            <docs-h3 id="api-examples">Examples</docs-h3>
                            <docs-h4 id="api-all-addresses">Get all addresses</docs-h4>
                            <blockquote>
                                GET https://api.mailpal.cc/v1/addresses
                            </blockquote>
                            <pre>
                                <code>
{
    "status": 200,
    "message": "ok",
    "data": [
        {
            "id": "abcde",
            "address": "test@mailpal.cc",
            "category": "Important",
            "active": true,
            "blocked": 2,
            "forwarded": 3,
            "created": 21448012432,
            "used": 21448012432
        }
    ]
}
                                </code>
                            </pre>
                        </docs-item>
                        
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
</template>

<script>
    import $ from 'jquery'

    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import DocsItem from '@/components/sections/DocsItem'
    import DocsH3 from '@/components/atoms/DocsH3'
    import DocsH4 from '@/components/atoms/DocsH4'
   
    export default {
        name: 'Docs',
        components: {
            Header,
            Footer,
            DocsItem,
            DocsH3,
            DocsH4
        },
        methods: {
            copy(event) {
                const e = event.target;
                const url = 'https://' + window.location.hostname + window.location.pathname
                var resp = url + '#' + e.id
                var $body = document.getElementsByTagName('body')[0]
                var $tempInput = document.createElement('INPUT');
                $body.appendChild($tempInput);
                $tempInput.setAttribute('value', resp)
                $tempInput.select();
                document.execCommand('copy');
                $body.removeChild($tempInput);
                var span = document.createElement('span')
                span.className = "tooltiptext"
                span.innerHTML = "Link copied!"
                e.appendChild(span)
                setTimeout(function () {
                    e.removeChild(span);
                }, 1000);
            },
            onHash(){
                var hash = $(location).attr('hash');
                if(hash){
                    $('a').each(function () {
                        $(this).removeClass('active');
                    })
                    $('a[href="' + hash + '"]').addClass('active');
                    var target = hash
                    let $target = $('a[href="' + hash + '"]');
                    if($target.length > 0){
                        $('html, body').stop().animate({
                            'scrollTop': $target.offset().top+2
                        }, 500, 'swing', function () {
                            window.location.hash = target;
                            $(document).on("scroll", this.onScroll);
                        });
                    }
                }
            },
            onScroll(){
                var scrollPos = $(document).scrollTop();
                $('#sidebar a').each(function () {
                    var currLink = $(this);
                    var refElement = $(currLink.attr("href"));
                    if (refElement.length > 0 && refElement.position().top + 100 <= scrollPos && refElement.position().top + refElement.height() + 100 > scrollPos) {
                        $('#sidebar ul li a').removeClass("active");
                        currLink.addClass("active");
                    }
                    else{
                    // currLink.removeClass("active");
                    }
                });
            }
        },
        created() {
            document.body.parentNode.style.background = "var(--background-dark)";
            document.title = "Docs - MailPal";
            window.addEventListener('hashchange', this.onHash())
        },
        destroyed(){
            window.removeEventListener('hashchange', this.onHash())
        },
        mounted(){
            this.onHash();
            $(document).on("scroll", this.onScroll);

            //smoothscroll
            $('a[href^="#"]').on('click', function (e) {
                e.preventDefault();
                $(document).off("scroll");
                
                $('a').each(function () {
                    $(this).removeClass('active');
                })
                $(this).addClass('active');
            
                var target = this.hash
                let $target = $(target);
                $('html, body').stop().animate({
                    'scrollTop': $target.offset().top+2
                }, 500, 'swing', function () {
                    window.location.hash = target;
                    $(document).on("scroll", this.onScroll);
                });
            });
        }
    }
</script>

<style>
    .content {
        width: 85%;
        margin: auto;
    }

    .tooltiptext {
        visibility: visible;
        width: 120px;
        background-color: var(--background);
        opacity: 0.8;
        color: var(--font);
        text-align: center;
        padding: 5px 0;
        border-radius: 10px;
        font-size: 15px;
        position: absolute;
        margin-left: 10px;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 1;
    }

    .icon {
        color: var(--font);
        position: absolute;
        top: 50%;
        transform: translate(0%, -50%);
        z-index: 1;
        right: calc(100% + 3px);
        /* font-size: 20px; */
        display: none;
    }

    .icon:hover .icon,
    h1:hover .icon,
    h2:hover .icon,
    h3:hover .icon,
    h4:hover .icon {
        display: inline-block;
    }

    .app--dark .layout__panel--editor,
    .app--dark .layout__panel--preview {
        color: hsla(0, 0%, 100%, .75)
    }

    blockquote,
    dl,
    ol,
    p,
    pre,
    ul {
        margin: 1.2em 0;
        font-size: 16px;
    }

    h1 {
        margin: 1.5em 0;
        margin-top: 1rem;
        line-height: 1.33;
        position: relative;
        cursor: pointer;
    }

    h4 {
        margin: 2em 0;
        margin-bottom: -10px;
        line-height: 1.33;
        position: relative;
        cursor: pointer;
    }

    h2,
    h3,
    h5,
    h6 {
        margin: 1.5em 0;
        margin-bottom: 20px;
        line-height: 1.33;
        position: relative;
        cursor: pointer;
    }

    h2:after,
    h3:after {
        content: "";
        display: block;
        position: relative;
        top: .33em;
        border-bottom: 1px solid hsla(0, 0%, 50%, .33)
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin: 0
    }

    dt {
        font-weight: 700
    }

    a {
        color: var(--primary);
        text-decoration: none;
        text-decoration-skip-ink: auto;
    }

    a:focus,
    a:hover {
        text-decoration: none
    }

    code,
    pre,
    samp {
        font-family: Roboto Mono, Lucida Sans Typewriter, Lucida Console, monaco, Courrier, monospace;
        font-size: .85em
    }

    code *,
    pre *,
    samp * {
        font-size: inherit
    }

    blockquote {
        color: var(--font-light);
        background-color: var(--background);
        line-height: 2em;
        padding-left: 1.5em;
        border-left: 5px solid var(--primary)
    }

    .app--dark .layout__panel--editor blockquote,
    .app--dark .layout__panel--preview blockquote {
        color: hsla(0, 0%, 100%, .4);
        border-left-color: hsla(0, 0%, 100%, .1)
    }

    code {
        background-color: rgba(0, 0, 0, .05);
        border-radius: 3px;
        padding: 2px 4px
    }

    hr {
        border: 0;
        border-top: 1px solid hsla(0, 0%, 50%, .33);
        margin: 2em 0
    }

    pre>code {
        background-color: var(--background);
        display: block;
        padding: .5em;
        -webkit-text-size-adjust: none;
        overflow-x: auto;
        white-space: pre
    }

    .toc ul {
        list-style-type: none;
        padding-left: 20px
    }

    table {
        background-color: transparent;
        border-collapse: collapse;
        border-spacing: 0
    }

    td,
    th {
        border-right: 1px solid #dcdcdc;
        padding: 8px 12px
    }

    td:last-child,
    th:last-child {
        border-right: 0
    }

    td {
        border-top: 1px solid #dcdcdc
    }

    mark {
        background-color: #f8f840
    }

    kbd {
        font-family: Lato, Helvetica Neue, Helvetica, sans-serif;
        background-color: #fff;
        border: 1px solid rgba(63, 63, 63, .25);
        border-radius: 3px;
        box-shadow: 0 1px 0 rgba(63, 63, 63, .25);
        color: #333;
        display: inline-block;
        font-size: .8em;
        margin: 0 .1em;
        padding: .1em .6em;
        white-space: nowrap
    }

    abbr[title] {
        border-bottom: 1px dotted #777;
        cursor: help
    }

    img {
        max-width: 100%
    }

    .task-list-item {
        list-style-type: none
    }

    .task-list-item-checkbox {
        margin: 0 .2em 0 -1.3em
    }

    .footnote {
        font-size: .8em;
        position: relative;
        top: -.25em;
        vertical-align: top
    }

    .page-break-after {
        page-break-after: always
    }

    .abc-notation-block {
        overflow-x: auto !important
    }

    .stackedit__html {
        margin-bottom: 100px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 900px
    }

    .stackedit__toc ul {
        padding: 0
    }

    .stackedit__toc ul a {
        margin: .5rem 0;
        padding: .5rem 1rem
    }

    .stackedit__toc ul {
        color: var(--font);
        font-size: 1.2rem;
    }

    .stackedit__toc ul ul a {
        margin: 0;
        padding: .1rem 1rem
    }

    .stackedit__toc li {
        display: block
    }

    .stackedit__toc a {
        display: block;
        color: inherit;
        text-decoration: none
    }

    .stackedit__toc a:active,
    .stackedit__toc a:focus,
    .stackedit__toc a:hover {
        background-color: rgba(0, 0, 0, .075);
        border-radius: 3px
    }

    .stackedit__left {
        position: fixed;
        display: none;
        width: 250px;
        height: 100%;
        top: 25%;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none
    }

    @media (min-width:1060px) {
        .stackedit__left {
            display: block
        }
    }

    .stackedit__right {}

    @media (min-width:1060px) {
        .stackedit__right {
            left: 250px;
            right: 250px;
        }
    }

    .stackedit--pdf blockquote {
        border-left-color: #ececec
    }

    .stackedit--pdf .stackedit__html {
        padding-left: 0;
        padding-right: 0;
        max-width: none
    }

    .active {
        color: var(--primary) !important;
    }
</style>