<template>
  <h4 :id="id" @click="copy($event)" title="Click here to copy a link to this section"><i class="fas fa-hashtag icon"></i><slot></slot></h4>
</template>

<script>
    export default {
        name: 'DocsH4',
        props: {
            id: String
        },
        methods: {
            copy(event) {
                const e = event.target;
                const url = 'https://' + window.location.hostname + window.location.pathname
                var resp = url + '#' + e.id
                var $body = document.getElementsByTagName('body')[0]
                var $tempInput = document.createElement('INPUT');
                $body.appendChild($tempInput);
                $tempInput.setAttribute('value', resp)
                $tempInput.select();
                document.execCommand('copy');
                $body.removeChild($tempInput);
                var span = document.createElement('span')
                span.className = "tooltiptext"
                span.innerHTML = "Link copied!"
                e.appendChild(span)
                setTimeout(function () {
                    e.removeChild(span);
                }, 1000);
            }
        },
    }
</script>

<style scoped>
  
</style>